import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import {
  withPlaceholder,
  withSitecoreContext,
} from "@sitecore-jss/sitecore-jss-react";
import { CoveoHeadless } from "@arm/ce-shared-components";
import config from "../../../temp/config";
import StarsRating from "../../SharedComponents/StarsRating";
import RenderingStyling from "../../../lib/RenderingStyling";
import { FILTER_AND_SEARCH_B_TEMPLATE_ID } from "../../../constants/TemplateIds";
import { UseGlobalState } from "../../../context";
import "./FilterAndSearchContainer.css";

const FilterAndSearchContainer = ({
  fields,
  rendering,
  sitecoreContext,
  SpotlightContentPlaceholder,
}) => {
  const [{ user }] = UseGlobalState();
  console.log(sitecoreContext.route?.fields?.EnableA2ZSorting?.value);
  var renderSpotlightContentContainer = () => {
    return (
      <>
        <div className="c-row u-flex u-justify-content-between u-margin-bottom-0 flex-no-wrap">
          <div>
            {sitecoreContext.route.templateId !=
            FILTER_AND_SEARCH_B_TEMPLATE_ID ? (
              <h2 className="u-margin-top-0">
                <Text field={fields.heading}></Text>
              </h2>
            ) : (
              ""
            )}
          </div>
          <StarsRating uid={rendering.uid} />
        </div>

        {sitecoreContext.route.templateId != FILTER_AND_SEARCH_B_TEMPLATE_ID ? (
          <p>
            <Text field={fields.shortDescription} />
          </p>
        ) : (
          ""
        )}
        {SpotlightContentPlaceholder}

        {sitecoreContext.route.templateId == FILTER_AND_SEARCH_B_TEMPLATE_ID ? (
          <div>
            <h2 className="u-margin-top-0">
              <Text field={fields.heading}></Text>
            </h2>
            <p>
              <Text field={fields.shortDescription} />
            </p>
          </div>
        ) : (
          ""
        )}
      </>
    );
  };

  const facetsConfig = fields.facets?.map((facet) => {
    if (facet.fields.field?.value) {
      return {
        title: `${facet.fields.label?.value}`,
        field: `${facet.fields.field.value}`,
        showCountsInFacet: facet.fields.showCountsInFacet?.value,
        isHierarchial: facet.fields.isHierarchial?.value,
      };
    }
  });

  const resultViewType = () => {
    let viewType = `${fields.resultType?.fields.value?.value}`;
    try {
      if (typeof window !== "undefined") {
        const userSelectedViewtype = localStorage?.getItem("viewtype");
        if (userSelectedViewtype) {
          viewType = JSON.parse(userSelectedViewtype);
        }
        return viewType;
      }
    } catch (err) {
      console.log(err);
    } finally {
      return viewType;
    }
  };

  const resultItemType = {
    type: resultViewType(),
    variant: `${fields.resultVariant?.fields.value?.value}`,
    hideBreadcrumb: true,
  };

  return (
    <section
      className="md:u-margin-left-2 md:u-margin-right-2"
      {...RenderingStyling(rendering.componentName)}
    >
      <div className="c-container is-full-width">
        {sitecoreContext.pageEditing ? (
          <div className="c-col c-col-12 u-margin-top-2">
            {renderSpotlightContentContainer()}
          </div>
        ) : (
          ""
        )}
        {user?.isLoginDone && (
          <CoveoHeadless
            CoveoOrganizationID={`${config.REACT_APP_COVEOORGANIZATIONID}`}
            CoveoGetTokenURI={`${config.REACT_APP_COVEOGETSEARCHTOKENURL}`}
            CoveoSearchHub={
              sitecoreContext.route.databaseName == "master"
                ? `CMS_` + `${fields.landingPageSearchHub?.fields.value?.value}`
                : `${fields.landingPageSearchHub?.fields.value?.value}`
            }
            CoveoQueryPipeline={`${config.REACT_APP_COVEOLANDINGPAGEQUERYPIPELINE}`}
            coveoQuery={`${fields.coveoQuery?.value}`}
            user={{ token: user.user?.token, isLoggedIn: user.isAuth }}
            facetsConfig={facetsConfig}
            resultItemType={resultItemType}
            showRecommendedTag={true}
            enableA2zSort={
              sitecoreContext.route?.fields?.EnableA2ZSorting?.value
            }
          >
            {!sitecoreContext.pageEditing
              ? renderSpotlightContentContainer()
              : ""}
          </CoveoHeadless>
        )}
      </div>
    </section>
  );
};

const FilterAndSearchContainerWithPlaceholderInjected = withPlaceholder(
  "SpotlightContentPlaceholder"
)(FilterAndSearchContainer);

const FilterAndSearchContainerWithPlaceholderAndSitecoreContext =
  withSitecoreContext()(FilterAndSearchContainerWithPlaceholderInjected);

export default FilterAndSearchContainerWithPlaceholderAndSitecoreContext;
