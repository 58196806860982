import React from "react";
import { Link, withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import config from "../../../temp/config";
import { CommunityForumCard } from "@arm/ce-shared-components/components/CoveoHeadless/CommunityComponents/Cards";
import "./CommunityForum.css";
import RenderingStyling from "../../../lib/RenderingStyling";
import { UseGlobalState } from "../../../context";

const CommunityFourm = ({ fields, sitecoreContext, rendering }) => {
  const [{ user }] = UseGlobalState();
  const forumID =
    fields.OrFromSingleForum?.fields["Telligent Id"].value.toString();
  const filterBy = fields.FilterBy?.displayName.toString();
  const numberofPosts =
    fields.NumberOfPosts?.value == "" ? 3 : fields.NumberOfPosts?.value;
  return (
    <div {...RenderingStyling(rendering.componentName)}>
      <CommunityForumCard
        CoveoOrganizationID={`${config.REACT_APP_COVEOORGANIZATIONID}`}
        CoveoGetTokenURI={`${config.REACT_APP_COVEOGETSEARCHTOKENURL}`}
        CoveoSearchHub={`${config.REACT_APP_COVEOCOMMUNITYSEARCHHUB}`}
        CoveoQueryPipeline={`${config.REACT_APP_COVEOCOMMUNITYQUERYPIPELINE}`}
        forumID={forumID}
        filterBy={filterBy}
        numberofPosts={numberofPosts}
        user={{ token: user.user?.token, isLoggedIn: user.isAuth }}
        CoveoRecommendedQueryPipeline={`${config.REACT_APP_COVEOCOMMUNITYRECOMMENDEDQUERYPIPELINE}`}
        CoveoRecommendedSearchHub={`${config.REACT_APP_COVEOHOMEPAGECOMMUNITYSEARCHHUB}`}
        searchEndPoint={`${config.REACT_APP_COVEOCACHEDENDPOINT}`}
      />

      {sitecoreContext.pageEditing ? (
        <div class="lg:u-padding-left-1 u-text-center">
          <Link
            field={fields.CTAlink}
            data-track="true"
            data-track-value={fields.CTAlink.value.text}
            className="c-cta-button is-medium is-primary"
          ></Link>
        </div>
      ) : (
        fields.CTAlink?.value?.href !== "" && (
          <div class="lg:u-padding-left-1 u-text-center">
            <Link
              field={fields.CTAlink}
              data-track="true"
              data-track-value={fields.CTAlink.value.text}
              className="c-cta-button is-medium is-primary"
            ></Link>
          </div>
        )
      )}
    </div>
  );
};

export default withSitecoreContext()(CommunityFourm);
