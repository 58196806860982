import React, { useEffect, useState } from "react";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import config from "../../../temp/config";
import "./StarRatingMobile.css";
import loadable from "@loadable/component";
import RenderingStyling from "../../../lib/RenderingStyling";
const FeedbackForm = loadable(
  () =>
    import("@arm/ce-shared-components/components/FeedbackForm/FeedbackForm"),
  { ssr: false }
);

const StarsRatingMobile = ({ uid, sitecoreContext }) => {
  const isMobile = useIsMobile();
  useEffect(() => {
    const targetNodeList = document.getElementsByClassName(
      "star-rating-resize-window"
    );
    if (targetNodeList.length > 0) {
      const targetNode = targetNodeList[0];
      const config = { childList: true, subtree: true };

      const callback = (mutationsList) => {
        for (const mutation of mutationsList) {
          if (mutation.addedNodes.length > 0) {
            const buttons = document.querySelectorAll(
              "#developer-hotjar-feedback-form button"
            );
            buttons &&
              buttons.forEach((button) => {
                if (button) {
                  button.className = "";
                  button.classList.add(
                    "c-cta-button",
                    "is-primary",
                    "is-medium"
                  );
                  button.setAttribute("style", "font-family : Lato !important");
                }
              });
              
              const headings = document.querySelectorAll(
                "#developer-hotjar-feedback-form h2"
              );
              headings.forEach((heading) => {
                if (heading) {
                  heading.setAttribute("style", "font-family : Lato !important;");
                }
              });
          }
        }
      };

      const observer = new MutationObserver(callback);
      observer.observe(targetNode, config);

      return () => observer.disconnect();
    }
  });
  
  return (
    isMobile && (
      <div
        className="xl:u-hide u-grid-auto-flow-column u-align-items-center u-justify-content-center u-gap-1 u-margin-bottom-2 dev-c-feedbackform"
        {...RenderingStyling("StarRatingMobile")}
      >
        <FeedbackForm />
      </div>
    )
  );
};

function useIsMobile() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    function handleWindowSizeChange() {
      setIsMobile(window.innerWidth < 1200);
    }
    window.addEventListener("resize", handleWindowSizeChange);
    handleWindowSizeChange();
    return () => window.removeEventListener("resize", handleWindowSizeChange);
  }, []);
  return isMobile;
}

export default withSitecoreContext()(StarsRatingMobile);
